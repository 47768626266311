import homeStyle from './Home.module.css'
import {CircularProgress, TextField} from "@mui/material";
import {CustomButton, CustomCheckbox, CustomFormControlLabel, CustomTextField} from "../assets/mui-styles/styles";
import {useEffect, useState} from "react";

import logo from '../assets/images/pianob.gif'
import {useParams} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../config";


const OverlayCircularProgress = ({ loading }) => {

    return (
        <div className={homeStyle.overlay}>
            <CircularProgress style={{color:'#E31E13'}}></CircularProgress>
        </div>
    );
};

export const Home = () => {
    const { id } = useParams();
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);

    const [formData, setFormData] = useState({
        nome: userData ? userData.nome : '',
        cognome: userData ? userData.cognome : '',
        email: userData ? userData.email : '',
        privacyAccepted: false,
        marketingAccepted: false,
        prefisso: '+',
        telefono: ''
    });

    const [errors, setErrors] = useState({});
    const [step, setStep] = useState(1);

    useEffect(() => {
        // Effettua la chiamata GET per ottenere i dati dell'utente
        axios.get(`${API_URL}/user/${id}`)
            .then(response => {
                setUserData(response.data);
                // Precompile form with data from id
                setFormData((prevState) => ({
                    ...prevState,
                    nome: response.data.nome,
                    cognome: response.data.cognome,
                    email: response.data.email
                }));
                setLoading(false);
            })
            .catch(error => {
                console.info(error)
                setLoading(false);
            });
    }, [id]);

    const validateForm = () => {
        let tempErrors = {};
        if (!formData.nome) tempErrors.nome = 'Campo obbligatorio';
        if (!formData.cognome) tempErrors.cognome = 'Campo obbligatorio';
        if (!formData.email) tempErrors.email = 'Campo obbligatorio';
        if (!formData.privacyAccepted) tempErrors.privacyAccepted = 'Campo obbligatorio';
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const nextStep = () => {
        if (validateForm()) {
            setStep(2);
            // Proceed to the next step or submit the form
        }
    };

    const confirmStep = () => {
        console.info(formData)
        handleUpdate()
    };

    const handleUpdate = () => {
        // Effettua la chiamata PUT per aggiornare i dati dell'utente
        setUpdating(true);
        axios.put(`${API_URL}/user-update/${id}`, formData)
            .then(response => {
                console.log('User updated successfully', response.data);
                setStep(3);
                setUpdating(false);
            })
            .catch(error => {
                console.error('Error updating user', error);
                setUpdating(false);
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: checked
        }));
    };

    return(
        <div className={homeStyle.mainContainer}>
            {loading &&
                <OverlayCircularProgress></OverlayCircularProgress>
            }
            {updating &&
                <OverlayCircularProgress></OverlayCircularProgress>
            }
            <div className={homeStyle.infoContainer}>
                <img src={logo} alt={'logo'} className={homeStyle.logo}/>
                {step === 1 && !loading &&  <>
                    <div className={homeStyle.textContainer}>
                        <p style={{fontSize: '25px'}}>
                            Ciao {userData.nome ? userData.nome : '---'}, <br/>
                            queste sono le tue INFO, se sono corrette clicca OK <br/>
                            Altrimenti corregile
                        </p>
                    </div>
                    <form>
                        <div className={homeStyle.formContainer}>
                            <CustomTextField
                                label="Nome"
                                variant="outlined"
                                name="nome"
                                value={formData.nome}
                                onChange={handleInputChange}
                                error={!!errors.nome}
                                helperText={errors.nome}
                                fullWidth
                            />
                            <CustomTextField
                                label="Cognome"
                                variant="outlined"
                                name="cognome"
                                value={formData.cognome}
                                onChange={handleInputChange}
                                error={!!errors.cognome}
                                helperText={errors.cognome}
                                fullWidth
                            />
                            <CustomTextField
                                label="Email"
                                variant="outlined"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                error={!!errors.email}
                                helperText={errors.email}
                                fullWidth
                            />
                        </div>
                        <div className={homeStyle.checkboxContainer}>
                            <CustomFormControlLabel
                                control={<CustomCheckbox
                                    name="privacyAccepted"
                                    checked={formData.privacyAccepted}
                                    onChange={handleCheckboxChange}
                                    error={!!errors.privacyAccepted}
                                    helperText={errors.privacyAccepted}
                                />}
                                label={
                                    <span style={{color: '#8b8a8b'}}>
                                            Accetto le {' '}
                                        <a
                                            href="/Informativa_privacy.pdf"
                                            target="_blank"
                                            style={{textDecoration: 'underline', color: '#696969'}}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                                Condizioni generali
                                            </a> e {' '}
                                        <a
                                            href="/Informativa_privacy.pdf"
                                            target="_blank"
                                            style={{textDecoration: 'underline', color: '#696969'}}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                                Informativa sulla privacy
                                            </a>*
                                        </span>
                                }
                            />
                            <span style={{color: '#d32f2f', paddingLeft: '1.9rem', fontSize: '12px'}}>
                                        {errors.privacyAccepted ? errors.privacyAccepted : ''}
                                    </span>
                            <CustomFormControlLabel
                                control={<CustomCheckbox
                                    name="marketingAccepted"
                                    checked={formData.marketingAccepted}
                                    onChange={handleCheckboxChange}
                                />}
                                label={
                                    <span style={{color: '#8b8a8b'}}>
                                            Accetto il trattamento dei miei dati per finalità di marketing
                                        </span>
                                }
                            />
                        </div>
                        <CustomButton
                            sx={{marginTop: '2rem'}}
                            onClick={() => nextStep()}
                        >
                            OK
                        </CustomButton>
                    </form>
                </>}
                {step === 2 && <>
                    <div className={homeStyle.textContainer}>
                        <p style={{fontSize: '25px'}}>
                            Se vuoi ricevere gli accrediti via whatsapp <br/>
                            lascia qui il tuo numero.
                        </p>
                    </div>
                    <form>
                    <div className={homeStyle.formContainer}>
                        <div style={{width:'100vw'}}>
                            <CustomTextField
                                className={homeStyle.prefix}
                                label="Prefisso"
                                variant="outlined"
                                name="prefisso"
                                value={formData.prefisso}
                                onChange={handleInputChange}
                            />
                            <CustomTextField
                                label="Telefono"
                                variant="outlined"
                                name="telefono"
                                value={formData.telefono}
                                onChange={handleInputChange}

                            />
                        </div>
                        </div>
                        <CustomButton
                            sx={{marginTop: '1rem'}}
                            onClick={() => confirmStep()}
                        >
                            Conferma
                        </CustomButton>
                    </form>
                </>}
                {step === 3 && <>
                    <div className={homeStyle.textContainer}>
                        <p style={{fontSize: '25px'}}>
                            Bella, <br/>
                            ci vediamo ai nostri eventi.
                        </p>
                    </div>
                </>}
            </div>
            <div className={homeStyle.imgContainer}>

            </div>
        </div>
    )
}